"use client"

import React, { useState } from "react"
import { toast } from "sonner"
import { z } from "zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import * as Form from "@radix-ui/react-form"
import { Button, FormFieldInput, useAuthModal } from "@store-platform/ui/common"
import {
  assignClerkAPIErrorsToForm,
  formErrorClassName,
  getPublicRootUrl,
  zodEmailValidator,
  zodNameValidator,
  zodPasswordValidator,
  zodUsernameValidator,
} from "@store-platform/utils"
import { useSignUp } from "@clerk/nextjs"
import { SvgCheck } from "@store-platform/ui/icons"

const formSchema = z.object({
  email: zodEmailValidator,
  password: zodPasswordValidator,
  firstName: zodNameValidator("first"),
  lastName: zodNameValidator("last"),
  username: zodUsernameValidator,
  verifiedReviewToken: z.string().optional(),
  referralLink: z.string().optional(),
})

type FormSchemaType = z.infer<typeof formSchema>

const developmentTag = process.env["NEXT_PUBLIC_DEVELOPMENT_TAG"]

export const SignUpForm = () => {
  const [signUpComplete, setSignUpComplete] = useState(false)
  const { isLoaded, signUp } = useSignUp()
  const form = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
  })
  const { toggleFooter } = useAuthModal()

  const processForm: SubmitHandler<FormSchemaType> = async (data) => {
    if (!isLoaded) {
      toast.error("Sign up is not available at the moment")
      return
    }

    try {
      await signUp.create({
        emailAddress: data.email,
        password: data.password,
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.username,
        ...(developmentTag ? { unsafeMetadata: { tag: developmentTag } } : {}),
      })
    } catch (error) {
      assignClerkAPIErrorsToForm(form, error, {
        username: "username",
        password: "password",
        first_name: "firstName",
        last_name: "lastName",
        email_address: "email",
      })
    }

    try {
      await signUp.prepareEmailAddressVerification({
        strategy: "email_link",
        redirectUrl: `${getPublicRootUrl()}/`,
      })
      setSignUpComplete(true)
      toggleFooter(false)
    } catch (error) {
      console.log(error)
      toast.error("Failed to create an account")
    }
  }

  if (signUpComplete) {
    return (
      <div className="flex flex-col justify-center items-center gap-y-3">
        <div className="bg-teal-50 rounded-full p-[14px] w-min">
          <SvgCheck className="text-teal-500 h-8 w-8" />
        </div>
        <div className="text-center">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">
            Sign Up Complete
          </h1>
          <p className="my-1 text-sm text-gray-500">
            Please check your email to activate your account
          </p>
        </div>
      </div>
    )
  }

  return (
    <Form.Root
      onSubmit={form.handleSubmit(processForm)}
      className="w-full max-w-[400px]"
      noValidate
    >
      <FormFieldInput
        registry={form.register("firstName")}
        error={form.formState.errors.firstName?.message}
        label="First Name"
        inputHtmlAttributes={{
          autoComplete: "given-name",
          autoFocus: true,
        }}
      />
      <FormFieldInput
        registry={form.register("lastName")}
        error={form.formState.errors.lastName?.message}
        label="Last Name"
        inputHtmlAttributes={{
          autoComplete: "family-name",
        }}
      />
      <FormFieldInput
        registry={form.register("email")}
        error={form.formState.errors.email?.message}
        label="Email"
        inputHtmlAttributes={{
          autoCapitalize: "off",
          autoComplete: "email",
          type: "email",
          minLength: 3,
        }}
      />
      <FormFieldInput
        registry={form.register("username")}
        error={form.formState.errors.username?.message}
        label="Username"
        leadingIcon={<span className="text-gray-500 sm:text-sm">@</span>}
        inputHtmlAttributes={{
          autoCapitalize: "off",
          autoComplete: "username",
          maxLength: 15,
        }}
      />
      <FormFieldInput
        registry={form.register("password")}
        error={form.formState.errors.password?.message}
        label="Password"
        inputHtmlAttributes={{
          type: "password",
          autoComplete: "new-password",
          maxLength: 50,
        }}
      />
      {form.formState.errors.root?.message && (
        <p className={formErrorClassName}>
          {form.formState.errors.root?.message}
        </p>
      )}
      <Form.Submit asChild>
        <Button
          variant="default"
          className="w-full"
          disabled={form.formState.isSubmitting}
        >
          Sign up
        </Button>
      </Form.Submit>
    </Form.Root>
  )
}
