"use client"

import React, { useState } from "react"
import { useAuth, useSignIn } from "@clerk/nextjs"
import { useRouter } from "next/navigation"
import * as Form from "@radix-ui/react-form"
import { z } from "zod"
import { SubmitHandler, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  assignClerkAPIErrorsToForm,
  formErrorClassName,
} from "@store-platform/utils"
import { Button, FormFieldInput } from "@store-platform/ui/common"

const formEmailSchema = z.object({
  email: z
    .string({
      required_error: "Email is required",
    })
    .email("Invalid email"),
})

type FormEmailSchemaType = z.infer<typeof formEmailSchema>

const formResetSchema = z.object({
  password: z.string({
    required_error: "New Password is required",
  }),
  code: z
    .string({
      required_error: "Code is required",
    })
    .regex(/^\d{6}$/, "Code must be 6 digits"),
})

type FormResetSchemaType = z.infer<typeof formResetSchema>

export function ResetPasswordForm() {
  const [successfulCreation, setSuccessfulCreation] = useState(false)

  const formEmail = useForm<FormEmailSchemaType>({
    resolver: zodResolver(formEmailSchema),
  })
  const formReset = useForm<FormResetSchemaType>({
    resolver: zodResolver(formResetSchema),
  })

  const router = useRouter()
  const { isSignedIn } = useAuth()
  const { isLoaded, signIn, setActive } = useSignIn()

  if (!isLoaded) {
    return null
  }

  if (isSignedIn) {
    router.push("/")
  }

  // Send the password reset code to the user's email
  const create: SubmitHandler<FormEmailSchemaType> = async (data) => {
    await signIn
      ?.create({
        strategy: "reset_password_email_code",
        identifier: data.email,
      })
      .then((_) => {
        setSuccessfulCreation(true)
      })
      .catch((err) => {
        console.log(err)
        assignClerkAPIErrorsToForm(formEmail, err, {
          email_address: "email",
        })
      })
  }

  // Reset the user's password.
  // Upon successful reset, the user will be
  // signed in and redirected to the home page
  const reset: SubmitHandler<FormResetSchemaType> = async (data) => {
    await signIn
      ?.attemptFirstFactor({
        strategy: "reset_password_email_code",
        code: data.code,
        password: data.password,
      })
      .then((result) => {
        if (result.status === "complete") {
          setActive({ session: result.createdSessionId })
          router.push("/")
        } else {
          console.log(result)
        }
      })
      .catch((err) => {
        assignClerkAPIErrorsToForm(formReset, err, {
          password: "password",
          code: "code",
        })
      })
  }

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="my-3 flex items-center justify-center space-x-1">
          <p className="text-center text-gray-500">
            {successfulCreation
              ? `We just sent a Reset Code to your Email Address ${formEmail.watch("email")}`
              : "Enter your email to reset your password"}
          </p>
        </div>

        {!successfulCreation && (
          <>
            <Form.Root
              onSubmit={formEmail.handleSubmit(create)}
              className="w-full sm:min-w-[250px]"
              noValidate
            >
              <FormFieldInput
                registry={formEmail.register("email")}
                error={formEmail.formState.errors.email?.message}
                label="Your Email Address"
                inputHtmlAttributes={{
                  autoCapitalize: "off",
                  autoComplete: "email",
                  type: "email",
                  autoFocus: true,
                }}
              />
              {formEmail.formState.errors.root?.message && (
                <p className={formErrorClassName}>
                  {formReset.formState.errors.root?.message}
                </p>
              )}
              <Form.Submit asChild>
                <Button
                  variant="default"
                  className="w-full"
                  disabled={formEmail.formState.isSubmitting}
                >
                  Send password reset code
                </Button>
              </Form.Submit>
            </Form.Root>
          </>
        )}

        {successfulCreation && (
          <>
            <Form.Root
              onSubmit={formReset.handleSubmit(reset)}
              className="w-full sm:min-w-[250px]"
              noValidate
            >
              <FormFieldInput
                registry={formReset.register("password")}
                error={formReset.formState.errors.password?.message}
                label="New Password"
                inputHtmlAttributes={{
                  autoComplete: "new-password",
                  type: "password",
                }}
              />
              <FormFieldInput
                registry={formReset.register("code")}
                error={formReset.formState.errors.code?.message}
                label="Reset Code"
                inputHtmlAttributes={{
                  autoComplete: "off",
                  type: "text",
                }}
              />
              {formReset.formState.errors.root?.message && (
                <p className={formErrorClassName}>
                  {formReset.formState.errors.root?.message}
                </p>
              )}
              <Form.Submit asChild>
                <Button
                  variant="default"
                  className="w-full"
                  disabled={formReset.formState.isSubmitting}
                >
                  Reset Password
                </Button>
              </Form.Submit>
            </Form.Root>
            <Button
              variant="outline"
              className="w-full text-gray-600 mt-2"
              onClick={() => {
                formReset.reset()
                setSuccessfulCreation(false)
              }}
            >
              Back
            </Button>
          </>
        )}
      </div>
    </>
  )
}
